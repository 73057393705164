<template>
    <div>
        <admin-calendar></admin-calendar>
    </div>
</template>

<script>
    import AdminCalendar from '../../../components/back/calendar/AdminCalendar'
    export default {
        name: "Calendrier",
        components: {
            AdminCalendar
        }
    }
</script>

<style scoped>

</style>
