<template>
    <div id="config-menu" :class="active ? 'active' : ''">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="w-100" v-if="getReasons && getReasons.length > 0" key="show">
                        <label for="days" class="form-label">{{$t('calendrier-admin.jour-a-masquer')}}</label>
                        <v-select id="days" class="w-100"  :options="EWeekDays.getList()" :multiple="true"
                                  :value="hiddenDays()"
                                  label="label" @input="onSelectWeeksDay" ></v-select>
                    </div>
                </div>
                <div class="col-md-12">
                    <label for="slot-min-time" class="form-label">{{$t('calendrier-admin.heure-min')}}</label>
                    <input type="time" id="slot-min-time" class="form-control"
                           :value="getConfiguration.slotMinTime ? getConfiguration.slotMinTime : '08:00:00' "
                           @input="onInputSlotMinTime">
                </div>
                <div class="col-md-12">
                    <label for="slot-max-time" class="form-label">{{$t('calendrier-admin.heure-max')}}</label>
                    <input type="time" id="slot-max-time" class="form-control"
                           :value="getConfiguration.slotMaxTime ? getConfiguration.slotMaxTime : '18:00:00' "
                           @input="onInputSlotMaxTime">
                </div>
                <div class="col-md-12">
                    <label for="slot-duration" class="form-label">{{$t('calendrier-admin.duree-slots')}}<span class="badge bg-primary">{{moment.duration(getConfiguration.slotDuration)._data.minutes === 0 ? '60 minutes' : moment.duration(getConfiguration.slotDuration)._data.minutes + ' minutes'}}</span></label>
                    <input type="range" id="slot-duration" class="custom-range" min="10" max="60" step="5"
                           :value="moment.duration(getConfiguration.slotDuration)._data.minutes === 0 ? 60 : moment.duration(getConfiguration.slotDuration)._data.minutes "
                           @input="onInputSlotDuration" @change="onInputSlotDuration"
                    >
                </div>
            </div>
        </div>
        <div class="options">
            <h3 @click="active = !active"><i class="bi bi-gear-fill"></i></h3>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    import moment from 'moment'
    import {EventBus} from '../../../event-bus'
    import EWeekDays from '../../../enums/EWeekDays'
    import configApi from '../../../api/configuration'
    import EEventBackgroundColorMode from "../../../enums/EEventBackgroundColorMode";


    export default {
        name: "ConfigMenu",
        data() {
            return {
                active: false,
                fullcalendarApi: null,
                moment: moment,
                EWeekDays: EWeekDays,
                EEventBackgroundColorMode: EEventBackgroundColorMode
            }
        },
        computed: {
            ...mapGetters({
                getFullCalendarApi: namespaces.adminCalendar+'getFullCalendarApi',
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getSlots: namespaces.adminCalendar + 'getSlots',
                getConfiguration: namespaces.adminCalendar + 'getConfiguration',
                getInitialized: namespaces.adminCalendar + 'getInitialized',
                getFrontInitialized: namespaces.calendar + 'getInitialized',
                getSelectedReason: namespaces.adminCalendar + 'getSelectedReason',
                getSelectedDoctor: namespaces.adminCalendar + 'getSelectedDoctor',
                getSelectedOffice: namespaces.adminCalendar + 'getSelectedOffice',
                getDate: namespaces.adminCalendar + 'getDate',
                getEndDate: namespaces.adminCalendar + 'getEndDate',
                //getCalendarOptions: namespaces.adminCalendar + 'getCalendarOptions',
                getOffices: namespaces.adminCalendar + 'getOffices',
                getDoctors: namespaces.adminCalendar + 'getDoctors',
                getReasons: namespaces.adminCalendar + 'getReasons',
                getFrontOffices: namespaces.calendar + 'getOffices',
                getFrontDoctors: namespaces.calendar + 'getDoctors',
                getFrontReasons: namespaces.calendar + 'getReasons',
                getFrontResources: namespaces.calendar + 'getResources',
                getAppointments: namespaces.adminCalendar + 'getAppointments',
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',
                getCurrentView: namespaces.adminCalendar + 'getCurrentView',
                getResources: namespaces.adminCalendar + 'getResources',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
                getEventBackgroundColorMode: namespaces.adminCalendar+'getEventBackgroundColorMode'
            }),


        },
        methods: {
            ...mapActions({
                setConfiguration: namespaces.adminCalendar + 'setConfiguration',
                setSelectedReason: namespaces.adminCalendar+'setSelectedReason',
            }),
            hiddenDays() {
                let days = []
                EWeekDays.getList().forEach(d=> {
                    this.getConfiguration.hiddenDays.forEach(hd=> {
                        if(hd === d.id)
                            days.push(d)
                    })
                })
                return days
            },
            onInputSlotDuration(e) {
                let duration = moment.duration(e.target.value, 'minutes')._data
                let toDisplay = duration.hours === 1 ? '00:60:00' : '00:' + duration.minutes + ':00'
                EventBus.$emit('admin-calendar-set-option', {
                    type: 'slotDuration',
                    data : toDisplay
                })

                this.setConfiguration({
                    slotDuration: toDisplay
                })

                configApi.store({
                    'user_id': this.getUser.id,
                    'key': 'slotDuration',
                    'value': toDisplay
                }, this.getApiToken)
            },
            onInputSlotMinTime(e) {
                EventBus.$emit('admin-calendar-set-option', {
                    type: 'slotMinTime',
                    data : e.target.value
                })
                this.setConfiguration({
                    slotMinTime: e.target.value
                })
                configApi.store({
                    'user_id': this.getUser.id,
                    'key': 'slotMinTime',
                    'value': e.target.value
                }, this.getApiToken)
            },
            onSelectReason(reason) {
                if(this.getSelectedReason && this.getSelectedReason.id === reason.id) {
                    this.setSelectedReason(null)
                }else {
                    this.setSelectedReason(reason)
                }
                EventBus.$emit('admin-refresh')
            },
            onSelectWeeksDay(e) {
                let days = []
                e.forEach(day=> {days.push(day.id)})
                EventBus.$emit('admin-calendar-set-option', {
                    type: 'hiddenDays',
                    data : days
                })
                this.setConfiguration({
                    hiddenDays: days
                })
                configApi.store({
                    'user_id': this.getUser.id,
                    'key': 'hiddenDays',
                    'value': JSON.stringify(days)
                }, this.getApiToken)

            },
            onInputSlotMaxTime(e) {
                EventBus.$emit('admin-calendar-set-option', {
                    type: 'slotMaxTime',
                    data :  e.target.value
                })
                this.setConfiguration({
                    slotMaxTime: e.target.value
                })
                configApi.store({
                    'user_id': this.getUser.id,
                    'key': 'slotMaxTime',
                    'value': e.target.value
                }, this.getApiToken)
            },
        },
        mounted() {

        }

    }
</script>

<style scoped lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    #config-menu {
        padding-top: 10px;
        border-radius: 5px 0 0 0 ;
        min-height: 200px;
        border-top: 1px solid $gray-500;
        border-bottom: 1px solid $gray-500;
        border-left: 1px solid $gray-500;
        z-index: 10000000;
        position: fixed;
        bottom: 15px;
        right: 0;
        width: 25vw;
        transform: translateX(100%);
        transition: all 0.7s ease-in-out;
        background-color: white;
        @media(max-width: 768px) {
            width: calc(100vw - 60px);
        }
        &.active {
            transform: translateX(0);
            -webkit-box-shadow: -10px 10px 35px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: -10px 10px 35px 0px rgba(0,0,0,0.75);
            box-shadow: -10px 10px 35px 0px rgba(0,0,0,0.75);
        }
        .options {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid $gray-500;
            border-bottom: 1px solid $gray-500;
            border-left: 1px solid $gray-500;
            background-color: white;
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 45px;
            width: 45px;
            border-radius: 5px 0 0 5px;
            transform: translateX(-100%);

            z-index: 30;
            h3 {
                margin: 0;

            }
        }
    }
</style>
