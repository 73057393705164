<template>
    <div class="container-fluid position-relative">
        <div v-show="loading" id="main-spinner" class="spinner-border" role="status">
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-4">
                <div class="w-100" v-if="getDoctorList" key="doctor-select">
                    <label for="doctor" class="form-label">{{$t('global.docteur')}}</label>
                    <v-select id="doctor" :options="getDoctorList" label="label" @input="onSelectDoctor" :value="getSelectedDoctor"></v-select>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="w-100">
                    <label for="date" class="form-label">{{$t('global.date')}}</label>
                    <input type="date" id="date" :value="getDate.format('YYYY-MM-DD')" class="form-control" @input="onSelectDate">
                </div>
            </div>
            <div class="col-lg-3 col-md-4" v-if="getDoctors && getDoctors.length > 1 && getCurrentView === 'resourceTimeGridDay'">
                <div class="d-flex flex-column justify-content-between">
                    <label class="form-label">{{$t('calendrier-admin.praticiens-du-jour')}}</label>
                    <label for="display_only_working_doctors" class="switch">
                        <input type="checkbox"  id="display_only_working_doctors" class="switch-input"
                               @click="onDisplayOnlyWorkingDoctors"
                               :checked="getDisplayOnlyWorkingDoctors"
                        >
                        <span class="switch-slider"></span>
                    </label>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="w-100">
                    <label for="date" class="form-label">{{ $t('calendar.trouver-premier-creneau') }}</label><br>
                    <button @click="onSearchFreeSlot" :disabled="loading" class="btn btn-primary"><i class="bi bi-search mr-2"></i>{{$t('global.rechercher')}}</button>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 position-relative">
                <span @click="setTopOptionsShown(!getTopOptionsShown)" class="h3 position-absolute clickable" data-toggle="collapse" data-target="#options-lieux" role="button" aria-expanded="false" aria-controls="options-lieux">
                    <i :class="!getTopOptionsShown ? 'fa-angle-double-down' : 'fa-angle-double-up'" class="fas"></i>
                </span>
                <hr>
            </div>
        </div>
        <div class="collapse" id="options-lieux">
            <transition name="fade" mode="out-in">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label mr-3">{{ $t('calendrier-admin.couleur-de-fond') }} :</label>
                            <div class="d-inline-block mr-5">
                                <label class="radio-container">{{ $t('global.lieu') }}
                                    <input @click="onSelectEventBackgroundColorMode"
                                           type="radio"
                                           :checked="getEventBackgroundColorMode === EEventBackgroundColorMode.office"
                                           :value="EEventBackgroundColorMode.office"
                                           name="background-color"
                                    >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="d-inline-block">
                                <label class="radio-container">{{ $t('global.motif') }}
                                    <input @click="onSelectEventBackgroundColorMode"
                                           type="radio"
                                           :checked="getEventBackgroundColorMode === EEventBackgroundColorMode.reason"
                                           :value="EEventBackgroundColorMode.reason"
                                           name="background-color"
                                    >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">{{ $t('global.lieux') }} :</label>
                        </div>
                        <template  v-for="office in getOffices">
                            <div class="col-md-2 mb-3" v-if="office.id !==0">
                                <div  class="badge w-100 p-1 clickable"
                                      :style="{
                                        backgroundColor: getEventBackgroundColorMode === EEventBackgroundColorMode.office ? office.color : null,
                                        border: getEventBackgroundColorMode === EEventBackgroundColorMode.office ? null : '1px solid '+office.color
                                }"
                                      @click="onSelectOffice(office)"
                                >
                                    <i v-if="getSelectedOffice && getSelectedOffice.id === office.id" class="fa fa-check text-success mr-2"></i>{{office.name}}
                                </div>
                            </div>
                        </template>
                        <div class="col-md-2">
                            <div class="badge w-100 p-1 clickable"
                                 :style="{
                                        backgroundColor: getEventBackgroundColorMode === EEventBackgroundColorMode.office ? '#3788d8' : null,
                                        border: getEventBackgroundColorMode === EEventBackgroundColorMode.office ? null : '1px solid #3788d8'
                                }"
                                 @click="onSelectOffice(0)"
                            >
                                <i v-if="getSelectedOffice === 0" class="fa fa-check text-success mr-2"></i>{{ $t('calendrier-admin.lieu-non-defini') }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">{{ $t('global.motifs') }} :</label>
                        </div>
                        <template v-for="reason in getReasons">
                            <div class="col-md-4 mb-3">
                                <div class="badge w-100 p-1 clickable"
                                     :style="{
                                        backgroundColor: getEventBackgroundColorMode === EEventBackgroundColorMode.reason ? reason.color : null,
                                        border: getEventBackgroundColorMode === EEventBackgroundColorMode.reason ? null : '1px solid '+reason.color
                                }"
                                     @click="onSelectReason(reason)"
                                >
                                    <i v-if="getSelectedReason && getSelectedReason.id === reason.id" class="fa fa-check text-success mr-2"></i>{{reason.label}}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <label class="form-label mr-3">{{ $t('calendrier-admin.fond-par-lieux') }} :</label>
                            <div class="d-inline-block mr-5">
                                <label class="radio-container"> Activer
                                    <input @click="onSelectEventBackgroundOffice(true)"
                                           type="radio"
                                           :checked="getEventBackgroundOffice"
                                           name="background-office-color"
                                    >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="d-inline-block">
                                <label class="radio-container"> {{ $t('global.desactiver') }}
                                    <input @click="onSelectEventBackgroundOffice(false)"
                                           type="radio"
                                           :checked="!getEventBackgroundOffice"
                                           name="background-office-color"
                                    >
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <template v-for="office in getOffices">
                            <div class="col-md-2 mb-3" v-if="office.id !==0">
                                <div class="badge w-100 p-1 clickable"
                                    :style="{
                                        backgroundColor: office.color + 70
                                    }"
                                    @click="onSelectBackgroundOffice(office)">
                                    <i v-if="getSelectedBackgroundOffice && getSelectedBackgroundOffice.id === office.id" class="fa fa-check text-success mr-2"></i>
                                    {{office.name}}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </transition>
            <div class="row">
                <div class="col-md-12">
                    <hr>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <FullCalendar  ref="adminCalendar" id="admin-calendar" :options="calendarOptions">
                </FullCalendar>
            </div>
        </div>
        <config-menu></config-menu>
    </div>
</template>

<script>
    import FullCalendar from '@fullcalendar/vue'
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid';
    import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
    import fr from '@fullcalendar/core/locales/fr';
    import en from '@fullcalendar/core/locales/en-gb';
    import pt from '@fullcalendar/core/locales/pt';
    import de from '@fullcalendar/core/locales/de';
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import calendarApi from '../../../api/calendar'
    import moment from 'moment'
    import {EventBus, EventList} from '@/event-bus'
    import EWeekDays from '../../../enums/EWeekDays'
    import ConfigMenu from "./ConfigMenu";
    import EHonoredStatus from "../../../enums/EHonoredStatus";
    import EEventBackgroundColorMode from "../../../enums/EEventBackgroundColorMode";
    import CreateModal from "./modal/create/CreateModal";
    import EditModal from "./modal/edit/EditModal";
    export default {
        name: "AdminCalendar",
        components: {
            FullCalendar,
            ConfigMenu
        },
        data() {
            return {
                fullcalendarApi: null,
                moment: moment,
                loading: false,
                editModale: null,
                config: Vue.$config,
                EWeekDays: EWeekDays,
                EHonoredStatus: EHonoredStatus,
                EEventBackgroundColorMode: EEventBackgroundColorMode,
                calendarOptions: {
                    schedulerLicenseKey: Vue.$config.full_calendar_licence,
                    customButtons: {
                        weekButton:{
                            text: this.$t('global.semaine'),
                            click: ()=> {
                                if(!this.getSelectedDoctor || this.getSelectedDoctor.id === 0){
                                    this.setSelectedDoctor(this.getDoctors.find(d=> true))
                                }
                                this.fullcalendarApi.changeView('timeGridWeek')
                                this.setCurrentView('timeGridWeek')
                            }
                        },
                        dayButton: {
                            text: this.$t('global.jour'),
                            click: ()=> {
                                if(!this.getSelectedDoctor || this.getSelectedDoctor.id === 0){
                                    this.setSelectedDoctor(this.getDoctors.find(d=> true))
                                }
                                this.fullcalendarApi.changeView('timeGridDay')
                                this.setCurrentView('timeGridDay')
                            }
                        },
                        doctorsButton: {
                            text: this.$t('global.docteurs'),
                            click: ()=> {
                                this.setSelectedDoctor(this.getDoctorList.find(d=> parseInt(d.id) === 0))
                                this.fullcalendarApi.changeView('resourceTimeGridDay')
                                this.setCurrentView('resourceTimeGridDay')
                                this.fullcalendarApi.refetchEvents()
                            }
                        }
                    },
                    stickyHeaderDates: true,
                    eventOverlap: true,
                    eventContent: (arg)=> {
                        return { html: arg.event.title }
                    },
                    headerToolbar: {
                        left: 'prev,next',
                        center: 'title',
                        right: 'weekButton,dayButton,doctorsButton'
                    },
                    plugins: [timeGridPlugin, resourceTimeGridPlugin , interactionPlugin],
                    resources: [

                    ],
                    views: {
                        resourceTimeGridDay: {
                            type: 'resourceTimeGridDay',
                            buttonText: 'Docteurs'
                        },
                        dayGrid: {
                            dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                        },
                        timeGrid: {
                            dayHeaderFormat: { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }
                        },
                    },
                    hiddenDays: [],
                    selectable: true,
                    slotMinTime: '08:00',
                    slotMaxTime: '18:00',
                    initialView: this.getCurrentView,
                    locale: localStorage.getItem('locale') || fr,
                    locales: [fr, en, pt, de],
                    weekends: true,
                    height: 'auto',// initial value
                    allDaySlot: true,
                    slotDuration: '00:15:00',
                    slotLabelFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: false
                    },
                    displayEventTime: false,
                },
            }
        },
        computed: {
            ...mapGetters({
                getConfiguration: namespaces.adminCalendar + 'getConfiguration',
                getSelectedReason: namespaces.adminCalendar + 'getSelectedReason',
                getSelectedDoctor: namespaces.adminCalendar + 'getSelectedDoctor',
                getSelectedOffice: namespaces.adminCalendar + 'getSelectedOffice',
                getSelectedBackgroundOffice: namespaces.adminCalendar + 'getSelectedBackgroundOffice',
                getDate: namespaces.adminCalendar + 'getDate',
                getEndDate: namespaces.adminCalendar + 'getEndDate',
                getOffices: namespaces.adminCalendar + 'getOffices',
                getDoctors: namespaces.adminCalendar + 'getDoctors',
                getDoctorList: namespaces.adminCalendar + 'getDoctorList',
                getReasons: namespaces.adminCalendar + 'getReasons',
                getFrontDoctors: namespaces.calendar + 'getDoctors',
                getFrontReasons: namespaces.calendar + 'getReasons',
                getFrontResources: namespaces.calendar + 'getResources',
                getAppointments: namespaces.adminCalendar + 'getAppointments',
                getUser: namespaces.auth + 'getUser',
                getCurrentView: namespaces.adminCalendar + 'getCurrentView',
                getResources: namespaces.adminCalendar + 'getResources',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
                getDisplayOnlyWorkingDoctors: namespaces.adminCalendar+'getDisplayOnlyWorkingDoctors',
                getEventBackgroundColorMode: namespaces.adminCalendar+'getEventBackgroundColorMode',
                getEventBackgroundOffice: namespaces.adminCalendar+'getEventBackgroundOffice',
                getTopOptionsShown: namespaces.layout+'getTopOptionsShown',
            }),
        },
        methods: {
            ...mapActions({
                setSlots: namespaces.adminCalendar+'setSlots',
                setConfiguration: namespaces.adminCalendar + 'setConfiguration',
                setSelectedReason: namespaces.adminCalendar+'setSelectedReason',
                setSelectedDoctor: namespaces.adminCalendar+'setSelectedDoctor',
                setSelectedOffice: namespaces.adminCalendar+'setSelectedOffice',
                setSelectedBackgroundOffice: namespaces.adminCalendar+'setSelectedBackgroundOffice',
                setDate: namespaces.adminCalendar+'setDate',
                setEndDate: namespaces.adminCalendar+'setEndDate',
                setOffices: namespaces.adminCalendar+'setOffices',
                setDoctors: namespaces.adminCalendar+'setDoctors',
                setReasons: namespaces.adminCalendar+'setReasons',
                setAppointments: namespaces.adminCalendar+'setAppointments',
                setSelectedSlot: namespaces.adminCalendar+'setSelectedSlot',
                setCurrentView: namespaces.adminCalendar+'setCurrentView',
                setResources: namespaces.adminCalendar+'setResources',
                setDisplayOnlyWorkingDoctors: namespaces.adminCalendar+'setDisplayOnlyWorkingDoctors',
                setEventBackgroundColorMode: namespaces.adminCalendar+'setEventBackgroundColorMode',
                setEventBackgroundOffice: namespaces.adminCalendar+'setEventBackgroundOffice',
                setTopOptionsShown: namespaces.layout+'setTopOptionsShown',
            }),
            async onDisplayOnlyWorkingDoctors() {
                this.setDisplayOnlyWorkingDoctors(!this.getDisplayOnlyWorkingDoctors)
                await this.setResources()
                this.fullcalendarApi.setOption('resources', this.getResources)
            },
            hiddenDays() {
                let days = []
                EWeekDays.getList().forEach(d=> {
                    this.getConfiguration.hiddenDays.forEach(hd=> {
                        if(hd === d.id)
                            days.push(d)
                    })
                })
                return days
            },
            async init() {
                this.loading = true
                this.calendarOptions.customButtons.weekButton.text = this.$t('global.semaine')
                this.calendarOptions.customButtons.dayButton.text = this.$t('global.jour')
                this.calendarOptions.customButtons.doctorsButton.text = this.$t('global.docteurs')

                if(localStorage.getItem('eventBackgroundOffice')) {
                    await this.setEventBackgroundOffice(JSON.parse(localStorage.getItem('eventBackgroundOffice')))
                }
                this.getUser.configuration.forEach((conf)=> {
                    let data = {}
                    data[conf.key] = conf.value
                    this.setConfiguration(data)
                })
                this.setCalendarConfiguration()
                if(!this.getOffices) await this.setOffices()
                await this.setDoctors()
                if(!this.getReasons)await this.setReasons()
                if(this.getCurrentView)this.fullcalendarApi.changeView(this.getCurrentView)
                this.fullcalendarApi.setOption('datesSet', async (info)=> {
                    await this.setCurrentView(info.view.type)
                    await this.setDate(moment(info.start))
                    await this.setConfiguration({
                        initialDate: info.start,
                        initialView: info.view.type
                    })
                    this.setCalendarConfiguration()
                    await this.setResources()
                    this.fullcalendarApi.setOption('resources', this.getResources)
                })
                //this.fullcalendarApi.setOption('resources', this.getResources)

                if(this.$route.params.doctorId) {
                    this.setSelectedDoctor(this.getDoctors.find(d=> parseInt(d.id) === parseInt(this.$route.params.doctorId)))
                    this.fullcalendarApi.changeView('timeGridWeek')
                    this.setCurrentView('timeGridWeek')
                } else if(!this.getSelectedDoctor && this.getDoctors.length > 1) {
                    this.setSelectedDoctor(this.getDoctorList.find(d=>d.id === 0))
                }else if(!this.getSelectedDoctor && this.getDoctors.length === 1) {
                    this.setSelectedDoctor(this.getDoctors.find(d=> true))
                    this.fullcalendarApi.changeView('timeGridWeek')
                    this.setCurrentView('timeGridWeek')
                }
                this.fullcalendarApi.setOption('resources', this.getResources)
                if(this.getDate) {
                    this.fullcalendarApi.gotoDate(this.getDate.format('YYYY-MM-DD'))
                }
                this.fullcalendarApi.setOption('events',(info)=> {
                    return this.fetchCalendarEvents(info).then(res=> {
                        return res
                    })
                })
                this.fullcalendarApi.setOption('select',(info)=> {
                    this.onSelect(info)
                })
                this.fullcalendarApi.setOption('eventDrop',(info)=> {
                    this.onEventDrop(info)
                })
                this.fullcalendarApi.setOption('eventClick',(info)=> {
                    this.onEventClick(info)
                })
                this.fullcalendarApi.setOption('eventResize',(info)=> {
                    this.onEventResize(info)
                })
                EventBus.$on('admin-refresh', ()=> {
                    this.fullcalendarApi.refetchEvents()
                })
                EventBus.$on('admin-calendar-set-option', (option) => this.fullcalendarApi.setOption(option.type, option.data))
                EventBus.$on('admin-calendar-set-view', (view) =>this.fullcalendarApi.changeView(view))
                this.loading = false
            },
            formatEvents(events = []) {
                return new Promise(resolve => {
                    events.forEach(event => {
                        let honoredClass = ''
                        let commentIcon = ''
                        if(this.config.etablissement.display_honored_button) {
                            if(event.honored === EHonoredStatus.undefined || event.honored == null)honoredClass='secondary-dot'
                            else if(event.honored === EHonoredStatus.not_honored)honoredClass='danger-dot'
                            else if(event.honored === EHonoredStatus.honored)honoredClass='success-dot'

                            if(event.comment) {
                                commentIcon = '<i class="far fa-comment-dots fa-sm text-light comment-icon"></i>'
                            }
                        }
                        let title = ''
                        if(event.user && event.user.id !== 4 && event.user.id !== 1 && event.user.full_name)  {
                            title +=  '<div class="title '+honoredClass+'">'+ event.user.full_name + commentIcon + '</div>'
                            title += '<br>Matricule : '+(event.user.matricule || 'inconnu')
                            title += '<br>Email : '+ (event.user.email || 'inconnu')
                            title += '<br>Téléphone : '+ (event.user.cellphone_number || 'inconnu')
                        }else {
                            title += '<div class="title '+honoredClass+'">Patient inconnu' + commentIcon + '</div>'
                            title += event.office ? '<br>Lieu :'+event.office.name : ''
                            title += event.reason ? '<br>Motif :'+event.reason.label : ''
                        }

                        if(event.comment) {
                            title+= '<br>Commentaire : ' + event.comment
                        }
                        event.start = event.date_debut
                        event.end = event.date_fin
                        event.title = title
                        event.editable = true
                        event.resourceId = event.doctor_id
                        if(this.getEventBackgroundColorMode === EEventBackgroundColorMode.office) {
                            event.backgroundColor = event.office_id ? this.getOffices.find(o=> o.id === event.office_id)?.color : null
                            event.borderColor =  event.reason_id ? this.getReasons.find(r=> r.id === event.reason_id)?.color : null
                        }else {
                            event.backgroundColor =  event.reason_id ? this.getReasons.find(r=> r.id === event.reason_id)?.color : null
                            event.borderColor = event.office_id ? this.getOffices.find(o=> o.id === event.office_id)?.color : null
                        }
                        event.textColor = 'Black'
                    })
                    resolve(events)
                })
            },
            async fetchCalendarEvents(info) {
                this.loading = true
                let params = {
                    startDateTime: moment(info.start).format('YYYY-MM-DD HH:mm:ss'),
                    endDateTime:  moment(info.end).format('YYYY-MM-DD HH:mm:ss'),
                    app_id: this.config.etablissement.id,
                    doctor_id: this.getSelectedDoctor && this.getSelectedDoctor.id !== 0 ? this.getSelectedDoctor.id : null,
                    office_id: this.getSelectedOffice ? this.getSelectedOffice.id : (this.getSelectedOffice === 0 ? 0 : null),
                    background_office_id: this.getSelectedBackgroundOffice ? this.getSelectedBackgroundOffice.id : null,
                    reason_id: this.getSelectedReason ? this.getSelectedReason.id : null,
                    active_background_event: this.getEventBackgroundOffice
                }
                let res = await calendarApi.getAdminAppointmentsBetweenDates(params)
                let slots = await this.formatEvents(res.data.appointments)
                let openingHoursSlots = res.data.openingHours
                let holidays = res.data.holidays
                this.loading = false
                return slots.concat(openingHoursSlots).concat(holidays)
            },
            onSelect(info) {
                this.setSelectedSlot(JSON.parse(JSON.stringify(info)))
                this.$modal.show(CreateModal,{
                },{
                    name: 'CreateModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade',
                    width:'1000px'
                })
            },
            onEventClick(info) {
                if(info.event.extendedProps.isHoliday) return
                this.setSelectedSlot(JSON.parse(JSON.stringify(info.event)))
                this.$modal.show(EditModal,{
                    appointmentId: parseInt(info.event.id)
                },{
                    name: 'EditModal',
                    clickToClose: true,
                    height: 'auto',
                    adaptive: true,
                    scrollable: true,
                    transition: 'fade',
                    width:'1000px'
                })
            },
            async onEventDrop(info) {
                this.loading = true
                try{
                    let res = await calendarApi.update({
                        doctor_id: info.event.extendedProps.doctor_id,
                        user_id: info.event.extendedProps.user_id,
                        office_id: info.event.extendedProps.office_id,
                        reason_id: info.event.extendedProps.reason_id,
                        app_id: Vue.$config.app_id,
                        date_debut: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
                        date_fin: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss')
                    }, info.event.id)
                }catch(e) {
                    console.log(e)
                    this.$toast.open({
                        message: this.$t('global.erreur-connexion'),
                        type: 'error',
                    });
                }
                this.loading = false
            },
            onSelectEventBackgroundColorMode(e) {
                this.setEventBackgroundColorMode(parseInt(e.target.value))
                this.fullcalendarApi.refetchEvents()
            },
            onSelectEventBackgroundOffice(bool) {
                this.setEventBackgroundOffice(bool)
                this.fullcalendarApi.refetchEvents()
            },
            async onEventResize(info) {
                this.loading = true
                try{
                    let res = await calendarApi.update({
                        doctor_id: info.event.extendedProps.doctor_id,
                        user_id: info.event.extendedProps.user_id,
                        office_id: info.event.extendedProps.office ? info.event.extendedProps.office.id : null,
                        reason_id: info.event.extendedProps.reason ? info.event.extendedProps.reason.id : null,
                        app_id: Vue.$config.app_id,
                        date_debut: moment(info.event.start).format('YYYY-MM-DD HH:mm:ss'),
                        date_fin: moment(info.event.end).format('YYYY-MM-DD HH:mm:ss')
                    }, info.event.id)
                }catch(e) {
                    console.log(e)
                    Vue.$toast.open({
                        message: this.$t('global.erreur-connexion'),
                        type: 'error',
                    });
                }

                this.loading = false
            },
            async onSelectDate(e) {
                await this.setDate( moment(e.target.value))
                this.fullcalendarApi.gotoDate(e.target.value)
                await this.setConfiguration({
                    initialDate: e.target.value
                })
                this.setCalendarConfiguration()
                await this.setResources()
                this.fullcalendarApi.setOption('resources', this.getResources)
            },
            onSelectOffice(office) {
                if(this.getSelectedOffice === 0 && office === 0
                    || this.getSelectedOffice && this.getSelectedOffice.id === office.id
                ) {
                    this.setSelectedOffice(null)
                }else {
                    this.setSelectedOffice(office)
                }
                this.fullcalendarApi.refetchEvents()
            },
            onSelectBackgroundOffice(office) {
                if(this.getSelectedBackgroundOffice === 0 && office === 0
                || this.getSelectedBackgroundOffice && this.getSelectedBackgroundOffice.id === office.id) {
                    this.setSelectedBackgroundOffice(null)
                } else {
                    this.setSelectedBackgroundOffice(office)
                }
                this.fullcalendarApi.refetchEvents()
            },
            onSelectDoctor(e) {
                if(e) {
                    this.setSelectedDoctor(e)
                    this.fullcalendarApi.changeView('timeGridWeek')
                }
                if(this.getSelectedDoctor.id === 0) {
                    this.fullcalendarApi.changeView('resourceTimeGridDay')
                }
                this.fullcalendarApi.refetchEvents()
            },
            onSelectReason(reason) {
                if(this.getSelectedReason && this.getSelectedReason.id === reason.id) {
                    this.setSelectedReason(null)
                }else {
                    this.setSelectedReason(reason)
                }

                this.fullcalendarApi.refetchEvents()
            },
            onSelectWeeksDay(e) {
                let days = []
                e.forEach(day=> {days.push(day.id)})
                this.fullcalendarApi.setOption('hiddenDays', days)
                this.setConfiguration({
                    hiddenDays: days
                })

            },
            onInputSlotMaxTime(e) {
                this.fullcalendarApi.setOption('slotMaxTime', e.target.value)
                this.setConfiguration({
                    slotMaxTime: e.target.value
                })
            },
            setCalendarConfiguration() {
                let config = this.getConfiguration || {}
                this.calendarOptions = Object.assign(this.calendarOptions, config)
                this.fullcalendarApi.render()
            },
            async onSearchFreeSlot() {
                this.loading = true
                let data = {
                    'date': this.getDate.format('YYYY-MM-DD HH:mm:ss'),
                    'doctor_id': this.getSelectedDoctor ? this.getSelectedDoctor.id : null,
                    'app_id': Vue.$config.app_id
                }
                try {
                    let res = await calendarApi.getFirstFreeSlotDateAdmin(data)
                    await this.setDate(moment(res.data.date))
                    this.fullcalendarApi.gotoDate(res.data.date)
                    this.loading = false
                }catch(e) {
                    this.$toast.open({
                        message: this.$t('global.erreur'),
                        type: 'info',
                    });
                    this.loading = false
                }
            },
        },
        mounted() {
            this.fullcalendarApi = this.$refs.adminCalendar.getApi()
            EventBus.$on(EventList.translation.setLocale,(locale)=> {
                this.fullcalendarApi.setOption('locale', locale)
                this.init()
            })
            this.init()
        },
        beforeDestroy() {
            EventBus.$off('admin-refresh')
            EventBus.$off('admin-calendar-set-option')
            EventBus.$off('admin-calendar-set-view')
            EventBus.$off(EventList.translation.setLocale)
        }
    }
</script>

<style scoped>
    #main-spinner {
        position: fixed;
        right: 0;
        top:10px;
        z-index: 10000;
    }
</style>
